import styled from "styled-components";
import Div from "app/components/Div";
import Button from "app/components/Button";

export const Title = styled.div`
  font-weight: bold;
  text-transform: capitalize;
  font-size: 18px;
  margin-bottom: 12px;
`;

export const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const OptionsDiv = styled(Div)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};
`;

export const Wrapper = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};
  transition: opacity 0.5s ease-in-out;
  padding: 20px 0 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Preference = styled.div<{ isEditing?: boolean }>`
  font-size: 14px;

  ${({ theme, isEditing }) =>
    isEditing &&
    `
    color: ${theme.colors.monochrome[3]};
  `}
`;

export const EditLink = styled.a`
  font-size: 10px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.black};
  font-weight: bold;
`;
